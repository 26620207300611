import { useEffect, useRef } from 'react';

export interface VisibilityHandlerProps {
  onVisible: () => void;
  children: React.ReactNode;
};
const VisibilityHandler: React.FC<VisibilityHandlerProps> = props => {
  const { children, onVisible } = props;
  const moreRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) onVisible();
      });
    }, { threshold: 0.5 });

    let continueCheck = true;
    let observed: typeof moreRef.current = null;
    const checkCurrent = () => {
      if (!continueCheck) return;
      if (moreRef.current) {
        observed = moreRef.current;
        observer.observe(observed);
      } else {
        setTimeout(checkCurrent, 1);
      }
    };
    setTimeout(checkCurrent, 1);
    return () => {
      continueCheck = false;
      if (observed) observer.unobserve(observed);
    };
  }, [moreRef, onVisible]);

  return <div ref={ moreRef }>{ children }</div>
};
export default VisibilityHandler;
