import moment from 'moment-timezone';
import styled from 'styled-components';

import CalendarDay, { CalendarDayProps } from './CalendarDay';
import { Theme } from '../theme';

const CalendarMonthContainer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`;
const CalendarMonthHeaderContainer = styled.div`
  flex-grow: 0;
  display: flex;
  background: ${props => (props.theme as Theme).calendar.colors.daysHeader.bg.toRgbString()};
  color: ${props => (props.theme as Theme).calendar.colors.daysHeader.text.toRgbString()};
`;
const CalendarMonthHeader = styled.div`
  text-align: center;
  font-weight: bold;
  flex-basis: ${100 / 7}%;
`;
const CalendarDaysContainer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-wrap: wrap;
  background: ${props => (props.theme as Theme).calendar.colors.daysBg.toRgbString()};
`;

export interface CalendarMonthProps {
  month: moment.Moment;
  dayStyle?: CalendarDayProps['style'];
  renderDay?: CalendarDayProps['render'];
  renderDayBody?: CalendarDayProps['renderBody'];
};
const CalendarMonth: React.FC<CalendarMonthProps> = props => {
  const startOf = props.month.clone().startOf('month');
  const endOf = props.month.clone().endOf('month');
  const days: moment.Moment[] = [];
  for (let i = startOf.clone(); i.isSameOrBefore(endOf); i.add(1, 'day')) {
    days.push(i.clone());
  }

  return (
    <CalendarMonthContainer>
      <CalendarMonthHeaderContainer>
        { moment.localeData().weekdaysShort().map(day => (
          <CalendarMonthHeader key={ day }>{ day }</CalendarMonthHeader>
        )) }
      </CalendarMonthHeaderContainer>
      <CalendarDaysContainer>
        <div style={{ flexBasis: 100 / 7 * startOf.day() + '%' }} />
        { days.map(day => (
          <CalendarDay
            key={ day.dayOfYear() }
            date={ day }
            render={ props.renderDay }
            renderBody={ props.renderDayBody }
            style={ props.dayStyle }
          />
        )) }
        <div style={{ flexBasis: 100 / 7 * (6 - endOf.day()) + '%' }} />
      </CalendarDaysContainer>
    </CalendarMonthContainer>
  );
};
export default CalendarMonth;
