import React, { useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { getDomain } from 'tldjs';

import { EventDetailEvent, useEvent } from '../api/events';
import { IconChevronLeft } from '../components/icons';
import { Markdown } from '../components/Markdown';
import OverlaySpin from '../components/OverlaySpin';
import { Theme } from '../theme';
import NotFoundPage from './NotFoundPage';

const EventTitle = styled.h2`
  margin: 0px;
`;
const EventSubtitle = styled.h3`
  margin: 0px;
  color: ${props => (props.theme as Theme).colors.primary.toRgbString()};
`;
const EventSubtitlePrefix = styled.span`
  font-weight: normal;
  opacity: 0.5;
`;

const EventHeaderWrapper = styled.div`
  display: flex;
  margin-top: 20px;
  margin-bottom: 20px;
`;
const EventBack = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  padding-left: 10px;
  padding-right: 20px;
  margin-right: 20px;
  font-size: 2em;

  background-color: ${props => (props.theme as Theme).colors.primary.toRgbString()};

  &:hover {
    color: ${props => (props.theme as Theme).colors.highlight.toRgbString()};
  }
`;

interface EventHeaderProps {
  loading: boolean;
  error: boolean;
  event: EventDetailEvent | null;
  onBack?: () => void;
}
const EventHeader: React.FC<EventHeaderProps> = props => {
  const { loading, error, event, onBack } = props;
  return (
    <EventHeaderWrapper>
      <EventBack onClick={ onBack }>
        <IconChevronLeft />
      </EventBack>
      <div>
        <EventTitle>
          { loading && 'Loading Event' }
          { error && 'Not Found' }
          { event && event.name }
        </EventTitle>
        { event && (
          <EventSubtitle><EventSubtitlePrefix>By </EventSubtitlePrefix>{ event.organizerName }</EventSubtitle>
        ) }
      </div>
    </EventHeaderWrapper>
  );
};

const BaseLbl = styled.span`
  font-weight: bold;
  color: ${props => (props.theme as Theme).colors.primary.toRgbString()};
`;
const EventFieldLbl = styled(BaseLbl)`
  padding-right: 20px;
  width: 200px;
`;
const EventFieldVal = styled.div`
  flex-grow: 1;
`;
const EventFieldWrapper = styled.div`
  display: flex;
`;

interface EventFieldProps {
  label: JSX.Element | string;
  value: JSX.Element | string;
};
const EventField: React.FC<EventFieldProps> = props => {
  return (
    <EventFieldWrapper>
      <EventFieldLbl>{ props.label }</EventFieldLbl>
      <EventFieldVal>{ props.value }</EventFieldVal>
    </EventFieldWrapper>
  );
};

const Dash: React.FC = () => <BaseLbl style={{ padding: '0px 20px' }}>&minus;</BaseLbl>;

interface EventDateFieldProps extends Pick<EventDetailEvent, 'startDate' | 'endDate' | 'schedule'> {
};
const EventDateField: React.FC<EventDateFieldProps> = props => {
  const { startDate, endDate, schedule } = props;
  if (schedule) {
    return (
      <EventField
        label="Recurring Event"
        value={<>
          { schedule.map(s => {
            let text: string[] = [];
            if (s.byMonthWeek) {
              if (s.byMonthWeek === 1) {
                text = [`${s.byMonthWeek}st`];
              } else if (s.byMonthWeek === 2) {
                text = [`${s.byMonthWeek}nd`];
              } else if (s.byMonthWeek === 3) {
                text = [`${s.byMonthWeek}rd`];
              } else {
                text = [`${s.byMonthWeek}th`];
              }
            } else {
              text = ['Every'];
            }
            if (s.byDay) text = [...text, s.byDay];
            if (s.byMonth) text = [...text, `in ${s.byMonth}`];
            if (s.startTime) text = [...text, s.startTime];
            return text.join(' ');
          }).join(' and ') }
        </>}
      />
    );
  }
  if (endDate) {
    if (startDate.isSame(endDate, 'day')) {
      return (
        <EventField
          label="Time"
          value={<>
            {startDate.format('llll')}<Dash />{endDate.format('LT')}
          </>}
        />
      );
    }
    return (
      <EventField
        label="Time"
        value={<>
          {startDate.format('llll')}<Dash />{endDate.format('llll')}
        </>}
      />
    );
  }
  return (
    <EventField
      label="Start Time"
      value={ startDate.format('llll') }
    />
  );
};

const displayLink = (url: string) => getDomain(new URL(url).hostname);

type EventPageParams = Record<'eventId', string>;
const EventPage: React.FC = () => {
  const { eventId } = useParams<EventPageParams>();
  if (!eventId) throw new Error('Should not be possible to get here without an eventId');

  const api = useEvent(eventId);
  const { loading, error, event } = api;

  const nav = useNavigate();
  const handleBack = useCallback(() => {
    if (!event) return;
    nav(`/${event.startDate.format('YYYY/MM')}`);
  }, [event, nav]);

  return (
    <OverlaySpin loading={ loading }>
      <EventHeader
        loading={ loading }
        error={ !!error }
        event={ event }
        onBack={ handleBack }
      />
      { error && <NotFoundPage thing="event" /> }
      { event && <>
        <EventDateField startDate={ event.startDate } endDate={ event.endDate } schedule={ event.schedule } />
        { event.locationName && <EventField label="Venue" value={ event.locationName } /> }
        { event.locationAddress && <EventField label="Address" value={ event.locationAddress } /> }
        { event.ticketUrl && <EventField label="Tickets" value={ <a href={ event.ticketUrl } target="_blank" rel="noreferrer">{ displayLink(event.ticketUrl) }</a> } /> }
        { event.description && <Markdown source={ event.description } /> }
      </> }
    </OverlaySpin>
  )
};
export default EventPage;
