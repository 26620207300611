import moment from 'moment-timezone';
import React, { useEffect, useMemo, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import { useEvents } from '../api/events';
import { usePublicHolidays } from '../api/publicHolidays';
import { useSuperEvents } from '../api/superEvents';
import EventsCalendar from '../components/EventsCalendar';
import OverlaySpin from '../components/OverlaySpin';

type AllEventsPageParams = Record<'year' | 'month', string>;

const AllEventsPage: React.FC = () => {
  const { year, month } = useParams<AllEventsPageParams>();
  const eventsApi = useEvents();
  const superEventsApi = useSuperEvents();
  const publicHolidaysApi  = usePublicHolidays();
  const nav = useNavigate();

  const date = useMemo(() => (
    year && month
      ? moment(`${year}-${month}`, 'YYYY-MM')
      : moment()
  ), [year, month]);

  const onCalendarChange = (date: moment.Moment) => {
    nav(`/${date.format('YYYY/MM')}`);
  };

  const [calendarType, setCalendarType] = useState<'calendar' | 'list'>('calendar');
  useEffect(() => {
    const timer = setInterval(() => {
      setCalendarType(window.innerWidth < 1200 ? 'list' : 'calendar');
    });
    return () => clearInterval(timer);
  }, []);

  return <div>
    <h1>All events</h1>
    <OverlaySpin loading={ eventsApi.loading }>
      { eventsApi.error && <p>ERROR: { eventsApi.error.message }</p> }
      { superEventsApi.error && <p>ERROR: { superEventsApi.error.message }</p> }
      { publicHolidaysApi.error && <p>ERROR: { publicHolidaysApi.error.message }</p> }
      <EventsCalendar
        events={ eventsApi.events || [] }
        superEvents={ superEventsApi.superEvents || [] }
        publicHolidays={ publicHolidaysApi.calendar }
        date={ date }
        onChange={ onCalendarChange }
        type={ calendarType }
      />
    </OverlaySpin>
  </div>
}
export default AllEventsPage;
