import type { Moment } from 'moment-timezone';
import moment from 'moment-timezone';
import { useMemo } from 'react';

import { BASE_API_URL } from '../config';
import { ApiBasicState, useApiBasic } from './util';

export interface RawSuperEventsListEvent {
  id: string;
  name: string;
  description: string | null;
  startDate: string;
  endDate: string;
  organizerOrganizationId: string | null;
};
export interface SuperEventsListEvent extends Omit<RawSuperEventsListEvent, 'startDate' | 'endDate' | 'doorTime'> {
  startDate: Moment;
  endDate: Moment;
};

export interface SuperEventsState extends ApiBasicState {
  superEvents: null | SuperEventsListEvent[];
};
export const useSuperEvents = (): SuperEventsState => {
  const state = useApiBasic(`${BASE_API_URL}/super-events`);
  const { response } = state;
  const superEvents = useMemo<SuperEventsState['superEvents']>(() => {
    if (!response?.data) return null;
    return (response.data as RawSuperEventsListEvent[]).map(raw => ({
      ...raw,
      startDate: moment(raw.startDate),
      endDate: moment(raw.endDate),
    }));
  }, [response]);
  return {
    ...state,
    superEvents,
  };
};
