import _ from 'lodash';
import moment from 'moment-timezone';
import ical from 'node-ical';
import React, { useCallback, useMemo } from 'react';
import styled from 'styled-components';

import { EventsListEvent } from '../api/events';
import Calendar, { CalendarProps } from './Calendar';
import { CalendarDayProps } from './CalendarDay';
import CalendarList from './CalendarList';
import { Theme } from '../theme';
import { useNavigate } from 'react-router-dom';
import { SuperEventsListEvent } from '../api/superEvents';

const EventTime = styled.span`
  font-weight: bold;
  color: ${props => (props.theme as Theme).calendar.colors.eventTime.toRgbString()};
`;
const EventName = styled.span`
`;
const EventRowBase = styled.div`
  text-overflow: ellipsis;
`;
const EventRow = styled(EventRowBase)`
  cursor: pointer;

  &:hover ${EventName} {
    color: ${props => (props.theme as Theme).colors.highlight.toRgbString()};
  }
`;
const SuperEventRow = styled(EventRowBase)`
  font-weight: bold;
  text-align: center;
  color: ${props => (props.theme as Theme).calendar.colors.superEvent.text.toRgbString()};
  background-color: ${props => (props.theme as Theme).calendar.colors.superEvent.bg.toRgbString()};
`;
const HolidayEventRow = styled(SuperEventRow)`
  color: ${props => (props.theme as Theme).calendar.colors.holidayEvent.text.toRgbString()};
  background-color: ${props => (props.theme as Theme).calendar.colors.holidayEvent.bg.toRgbString()};
`

interface CalendarEventProps {
  event: EventsListEvent;
  onClick?: (event: EventsListEvent) => void;
}
const CalendarEvent: React.FC<CalendarEventProps> = props => {
  const { event, onClick } = props;
  const start = event.startDate.clone().local();
  return (
    <EventRow onClick={ () => onClick && onClick(event) }>
      <EventTime>{ start.format('LT') }</EventTime>&nbsp;
      <EventName>{ event.name }</EventName>
    </EventRow>
  );
};

interface CalendarSuperEventProps {
  superEvent: SuperEventsListEvent;
}
const CalendarSuperEvent: React.FC<CalendarSuperEventProps> = props => {
  const { superEvent } = props;
  return (
    <SuperEventRow>
      <EventName>{ superEvent.name }</EventName>
    </SuperEventRow>
  );
};
interface CalendarHolidayEventProps {
  holiday: HolidayEvent;
}
const CalendarHolidayEvent: React.FC<CalendarHolidayEventProps> = props => {
  const { holiday } = props;
  return (
    <HolidayEventRow>
      <EventName>{ holiday.name }</EventName>
    </HolidayEventRow>
  );
};

interface GetEventsForDayProps {
  indexedEvents: { [key: string]: EventsListEvent[] };
  recurringEvents: EventsListEvent[];
  superEvents: SuperEventsListEvent[];
  publicHolidays: ical.CalendarResponse | null;
  date: moment.Moment;
}
interface HolidayEvent extends Pick<EventsListEvent, 'name' | 'description'> {
}

const getEventsForDay = (props: GetEventsForDayProps) => {
  const { indexedEvents, recurringEvents, superEvents, publicHolidays, date } = props;

  const eventsOnThisDay = indexedEvents[date.format('YYYY-MM-DD')] || [];

  const dow = date.format('dddd');
  const mnum = date.get('month') + 1;
  const dnum = date.get('date');
  const wnum = Math.ceil(dnum / 7);

  for (const e of recurringEvents) {
    for (const s of e.schedule || []) {
      if (s.byDay && s.byDay !== dow) continue;
      if (s.byMonth && s.byMonth !== mnum) continue;
      if (s.byMonthDay && s.byMonthDay !== dnum) continue;
      if (s.byMonthWeek && s.byMonthWeek !== wnum) continue;
      if (eventsOnThisDay.find(ie => ie.id === e.id)) continue;

      const startDate = date.clone();
      if (s.startTime) {
        const timeParts = s.startTime.split(':');
        if (timeParts[0]) startDate.set('hour', parseInt(timeParts[0], 10));
        if (timeParts[1]) startDate.set('minute', parseInt(timeParts[1], 10));
        startDate.set('second', 0);
      }
      eventsOnThisDay.push({
        ...e,
        startDate,
      });
      break;
    }
  }
  eventsOnThisDay.sort((a, b) => a.startDate.diff(b.startDate));

  const holidaysOnThisDay: HolidayEvent[] = [];
  for (const e of Object.values(publicHolidays || {})) {
    if (e.type !== 'VEVENT') continue;
    const evToAdd = { name: (e.summary as any).val, description: e.description };
    const start = moment(e.start).startOf('day');
    const end = (e.end ? moment(e.end) : start.clone()).endOf('day');

    // TODO this is... probably wrong, but it works for AU TZ for the moment
    // for some reason, the public holidays are all +1 day, but rrule is correct
    if (!e.rrule) {
      start.subtract('1', 'day');
      end.subtract('1', 'day');
    }

    if (date.isBetween(start, end)) {
      holidaysOnThisDay.push(evToAdd);
    } else if (e.rrule) {
      const res = e.rrule.between(
        date.clone().startOf('day').toDate(),
        date.clone().endOf('day').toDate(),
      );
      if (res.length > 0) holidaysOnThisDay.push(evToAdd);
    }
  }

  const supersOnThisDay = superEvents.filter((superEvent: SuperEventsListEvent) =>
    date.isBetween(superEvent.startDate, superEvent.endDate, 'day', '[]')
  );

  return {
    events: eventsOnThisDay,
    supers: supersOnThisDay,
    holidays: holidaysOnThisDay,
  };
};

export interface EventsCalendarProps {
  events: EventsListEvent[];
  superEvents: SuperEventsListEvent[];
  publicHolidays: null | ical.CalendarResponse;
  date?: CalendarProps['date'];
  onChange?: CalendarProps['onChange'];
  type?: 'calendar' | 'list',
}
const EventsCalendar: React.FC<EventsCalendarProps> = props => {
  const { events, superEvents, publicHolidays, type } = props;
  const nav = useNavigate();

  const eventsToShow = events.filter(e => !e.schedule);
  const indexedEvents = useMemo<Record<string, EventsListEvent[]>>(
    () => _.groupBy(eventsToShow, (event: EventsListEvent) => event.startDate.format('YYYY-MM-DD')),
    [eventsToShow],
  );
  const recurringEvents = useMemo<EventsListEvent[]>(
    () => events.filter(e => !_.isNil(e.schedule)),
    [events],
  );

  const days = useMemo(() => {
    const daysStr = Object.keys(indexedEvents);

    if (daysStr.length === 0) return [];

    let daysObj = daysStr.map(d => moment(d));
    daysObj.sort((a, b) => a.valueOf() - b.valueOf());

    let currDay: moment.Moment = daysObj[0];
    const lastDay = daysObj[daysObj.length - 1];
    while (currDay.isBefore(lastDay)) {
      if (getEventsForDay({
        indexedEvents,
        recurringEvents,
        publicHolidays: null,
        superEvents: [],
        date: currDay,
      }).events.length > 0) {
        daysObj.push(currDay.clone());
      }
      currDay.add(1, 'day');
    }

    daysObj = _.uniqBy(daysObj, d => d.format('YYYY-MM-DD'));
    daysObj.sort((a, b) => a.valueOf() - b.valueOf());
    return daysObj;
  }, [indexedEvents, recurringEvents]);

  const handleEventClick = useCallback((event: EventsListEvent) => {
    nav(`/events/${event.id}`);
  }, [nav]);

  const renderDayBody = useCallback((renderProps: CalendarDayProps) => {
    if (renderProps.date.isBefore(new Date(), 'day')) {
      return null;
    }

    const { events: eventsOnThisDay, supers: supersOnThisDay, holidays: holidaysOnThisDay } = getEventsForDay({
      indexedEvents,
      recurringEvents,
      publicHolidays,
      superEvents,
      date: renderProps.date,
    });

    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        { supersOnThisDay.map(superEvent => (
          <CalendarSuperEvent
            key={ superEvent.id }
            superEvent={ superEvent }
          />
        )) }
        { holidaysOnThisDay.map(holiday => (
          <CalendarHolidayEvent
            key={ holiday.name }
            holiday={ holiday }
          />
        )) }
        { eventsOnThisDay.map(event => (
          <CalendarEvent
            key={ event.id }
            event={ event }
            onClick={ handleEventClick }
          />
        )) }
      </div>
    )
  }, [indexedEvents, superEvents, recurringEvents, publicHolidays, handleEventClick]);

  if (props.events.length === 0)
    return <h2>No upcoming events</h2>;

  if (type === 'list') {
    return (
      <CalendarList
        days={ days }
        renderDayBody={ renderDayBody }
      />
    )
  } else {
    return (
      <Calendar
        style={{ height: '600px' }}
        renderDayBody={ renderDayBody }
        { ..._.pick(props, ['date', 'onChange']) }
      />
    );
  }
};
export default EventsCalendar;
