import styled, { keyframes } from 'styled-components';

import { Theme } from '../theme';

// https://codepen.io/AlexWarnes/pen/jXYYKL

const ConfigureClockwiseKeyframes = keyframes`
  0% {
    transform: rotate(0);
  }
  25% {
    transform: rotate(90deg);
  }
  50% {
    transform: rotate(180deg);
  }
  75% {
    transform: rotate(270deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;
const ConfigureXClockwiseKeyframes = keyframes`
  0% {
    transform: rotate(45deg);
  }
  25% {
    transform: rotate(-45deg);
  }
  50% {
    transform: rotate(-135deg);
  }
  75% {
    transform: rotate(-225deg);
  }
  100% {
    transform: rotate(-315deg);
  }
`;

interface SpinnerBoxProps {
  hyp: number;
}
const SpinnerBox = styled.div<SpinnerBoxProps>`
  width: ${props => props.hyp}px;
  height: ${props => props.hyp}px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
`;

interface BorderBoxProps {
  opp: number;
  border: number;
}
const ConfigureBorder1 = styled.div<BorderBoxProps>`
  width: ${props => props.opp}px;
  height: ${props => props.opp}px;
  padding: ${props => props.border}px;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${props => (props.theme as Theme).spinner.colors.border1.toRgbString()};
  animation: ${ConfigureClockwiseKeyframes} 3s ease-in-out 0s infinite alternate;
`;
const ConfigureBorder2 = styled.div<BorderBoxProps>`
  width: ${props => props.opp}px;
  height: ${props => props.opp}px;
  padding: ${props => props.border}px;
  left: -${props => props.opp}px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${props => (props.theme as Theme).spinner.colors.border2.toRgbString()};
  transform: rotate(45deg);
  animation: ${ConfigureXClockwiseKeyframes} 3s ease-in-out 0s infinite alternate;
`;

const ConfigureCore = styled.div`
  width: 100%;
  height: 100%;
  background: ${props => (props.theme as Theme).spinner.colors.core.toRgbString()};
`;

export interface InlineSpinProps {
  loading?: boolean;
  size?: number;
  border?: number;
};
const InlineSpin: React.FC<InlineSpinProps> = props => {
  const { size = 50, border = size / 25 } = props;
  if (props.loading === false) return <></>;
  const hyp = size;
  const opp = hyp / Math.sqrt(2);
  return (
    <SpinnerBox hyp={ hyp }>
      <ConfigureBorder1 opp={ opp } border={ border }>
        <ConfigureCore />
      </ConfigureBorder1>
      <ConfigureBorder2 opp={ opp } border={ border }>
        <ConfigureCore />
      </ConfigureBorder2>
    </SpinnerBox>
  );
};
export default InlineSpin;
