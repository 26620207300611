import moment from 'moment-timezone';
import styled from 'styled-components';

import { Theme } from '../theme';

export const CalendarDayRendererContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  background: ${props => (props.theme as Theme).calendar.colors.dayBg.toRgbString()};
`;
export interface CalendarDayRendererProps {
  date: moment.Moment;
  style?: React.CSSProperties;
  highlight?: React.CSSProperties['color'];
  renderBody?: (props: CalendarDayRendererProps) => React.ReactNode;
};
export const DefaultCalendarDayRenderer: React.FC<CalendarDayRendererProps> = props => {
  return (
    <CalendarDayRendererContainer style={ props.style }>
      <strong style={{
        textAlign: 'right',
        color: props.highlight,
      }}>{ props.date.format('DD') }</strong>
      { props.renderBody && props.renderBody(props) }
    </CalendarDayRendererContainer>
  );
};

export const CalendarDayContainer = styled.div`
  flex-grow: 1;
  flex-basis: ${100 / 7}%;
`;
export interface CalendarDayProps extends CalendarDayRendererProps {
  style?: React.CSSProperties;
  render?: (props: CalendarDayRendererProps) => React.ReactNode;
};
const CalendarDay: React.FC<CalendarDayProps> = props => {
  const startOf = props.date.clone().startOf('day');
  return (
    <CalendarDayContainer style={ props.style }>
      { props.render
        ? props.render(props)
        : <DefaultCalendarDayRenderer { ...{ ...props, date: startOf } } />
      }
    </CalendarDayContainer>
  )
};
export default CalendarDay;
