import ical from 'node-ical';
import { useMemo } from 'react';

import { BASE_API_URL } from '../config';
import { ApiBasicState, useApiBasic } from './util';

export interface PublicHolidaysState extends ApiBasicState {
  calendar: null | ical.CalendarResponse;
};
export const usePublicHolidays = (): PublicHolidaysState => {
  const state = useApiBasic(`${BASE_API_URL}/holidays/au_en-au`);
  const { response } = state;
  const calendar = useMemo<PublicHolidaysState['calendar']>(() => {
    if (!response?.data) return null;
    return ical.parseICS(response.data);
  }, [response]);
  return {
    ...state,
    calendar,
  };
};
