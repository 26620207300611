import _ from 'lodash';
import moment from 'moment-timezone';
import { useCallback, useState } from 'react';
import styled, { useTheme } from 'styled-components';

import { Theme } from '../theme';
import { CalendarDayProps, DefaultCalendarDayRenderer } from './CalendarDay';
import CalendarMonth, { CalendarMonthProps } from './CalendarMonth';
import { IconCalendarInsertUp, IconChevronDoubleLeft, IconChevronDoubleRight, IconChevronLeft, IconChevronRight } from './icons';

const CalendarContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
const CalendarHeader = styled.div`
  display: flex;
  flex-direction: row;
  text-align: center;
  font-size: 1.5em;
  font-weight: bold;
  background: ${props => (props.theme as Theme).calendar.colors.monthHeader.bg.toRgbString()};
  color: ${props => (props.theme as Theme).calendar.colors.monthHeader.text.toRgbString()};
  padding: 20px;
`;
const CalendarHeaderTitle = styled.div`
  flex-grow: 1;
`;
const CalendarHeaderButton = styled.div`
  cursor: pointer;
  width: 1.5em;
  color: ${props => (props.theme as Theme).calendar.colors.monthHeader.buttons.text.toRgbString()};
  &:hover {
    color: ${props => (props.theme as Theme).calendar.colors.monthHeader.buttons.hoverText.toRgbString()};
  }
`;
const CalendarHeaderButtonLeft = styled(CalendarHeaderButton)`
  padding-left: 15px;
`;
const CalendarHeaderButtonRight = styled(CalendarHeaderButton)`
  padding-right: 15px;
`;

export interface CalendarProps {
  style?: React.CSSProperties;
  date?: moment.Moment;
  defaultDate?: moment.Moment;
  onChange?: (date: moment.Moment) => void;
  renderDay?: CalendarMonthProps['renderDay'];
  renderDayBody?: CalendarMonthProps['renderDayBody'];
  dayStyle?: CalendarMonthProps['dayStyle'];
  todayHighlight?: CalendarDayProps['highlight'];
  pastStyle?: CalendarDayProps['style'];
};
const Calendar: React.FC<CalendarProps> = props => {
  const theme = useTheme() as Theme;
  const {
    defaultDate = moment(),
    todayHighlight = theme.calendar.colors.todayHighlight.toRgbString(),
    pastStyle = { opacity: '50%' },
    onChange,
    date: controlledDate,
  } = props;

  const [intDate, setIntDate] = useState<moment.Moment>(props.date || defaultDate);
  const realDate = controlledDate || intDate;
  const setDate = useCallback((
    getNewVal: (date: moment.Moment) => moment.Moment,
  ) => {
    if (controlledDate) {
      onChange?.(getNewVal(controlledDate));
    } else if (!controlledDate) {
      setIntDate(d => {
        const newVal = getNewVal(d);
        onChange?.(newVal);
        return newVal;
      });
    }
  }, [controlledDate, onChange, setIntDate]);

  const prevMonth = useCallback(() => setDate(d => d.clone().subtract(1, 'month')), [setDate]);
  const nextMonth = useCallback(() => setDate(d => d.clone().add(1, 'month')), [setDate]);
  const prevYear = useCallback(() => setDate(d => d.clone().subtract(1, 'year')), [setDate]);
  const nextYear = useCallback(() => setDate(d => d.clone().add(1, 'year')), [setDate]);
  const reset = useCallback(() => setDate(() => moment()), [setDate]);

  const now = moment();
  const defaultRenderDay = useCallback((dayProps: CalendarDayProps) => {
    const isPast = dayProps.date.isBefore(now, 'day');
    return <DefaultCalendarDayRenderer
      { ...dayProps }
      style={ isPast ? pastStyle : undefined }
      highlight={ dayProps.date.isSame(now, 'day') ? todayHighlight : undefined }
    />
  }, [now, pastStyle, todayHighlight]);

  return (
    <CalendarContainer style={ props.style }>
      <CalendarHeader>
        <CalendarHeaderButton onClick={ prevMonth }><IconChevronLeft /></CalendarHeaderButton>
        <CalendarHeaderButtonRight onClick={ prevYear }><IconChevronDoubleLeft /></CalendarHeaderButtonRight>
        <CalendarHeaderTitle>{ realDate.format('MMMM, YYYY') }</CalendarHeaderTitle>
        <CalendarHeaderButtonLeft onClick={ reset }><IconCalendarInsertUp /></CalendarHeaderButtonLeft>
        <CalendarHeaderButton onClick={ nextYear }><IconChevronDoubleRight /></CalendarHeaderButton>
        <CalendarHeaderButton onClick={ nextMonth }><IconChevronRight /></CalendarHeaderButton>
      </CalendarHeader>
      <CalendarMonth
        month={ realDate }
        renderDay={ props.renderDay || defaultRenderDay }
        { ..._.pick(props, ['renderDayBody', 'dayStyle']) }
      />
    </CalendarContainer>
  );
};
export default Calendar;
