import { Routes, Route } from 'react-router-dom';

import AppWrapper from './components/layout/AppWrapper';
import AllEventsPage from './pages/AllEventsPage';
import AuditScrapeTargetsPage from './pages/AuditScrapeTargetsPage';
import EventPage from './pages/EventPage';
import LoginPage from './pages/LoginPage';
import LogoutPage from './pages/LogoutPage';
import NotFoundPage from './pages/NotFoundPage';

function App() {
  return (
    <AppWrapper>
      <Routes>
        <Route index element={ <AllEventsPage /> } />
        <Route path="login" element={ <LoginPage /> } />
        <Route path="logout" element={ <LogoutPage /> } />
        <Route path="events/:eventId" element={ <EventPage /> } />
        <Route path=":year/:month" element={ <AllEventsPage /> } />
        <Route path="audit/scrape-targets" element={ <AuditScrapeTargetsPage /> } />
        <Route path="*" element={ <NotFoundPage /> } />
      </Routes>
    </AppWrapper>
  );
};

export default App;
