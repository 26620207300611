import { useAuth0 } from '@auth0/auth0-react';
import { useEffect } from 'react';

import OverlaySpin from '../components/OverlaySpin';

const LoginPage = () => {
  const { loginWithRedirect } = useAuth0();
  useEffect(() => {
    loginWithRedirect();
  });
  return <OverlaySpin><div>Logging in...</div></OverlaySpin>;
}
export default LoginPage;
