import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import _ from 'lodash';
import { useCallback, useContext, useEffect, useState } from 'react';

import { TokenContext } from '../components/layout/AppWrapper';

export const useToken = () => useContext(TokenContext);

export interface ApiBasicState {
  url: string;
  loading: boolean;
  response: AxiosResponse | null;
  error: Error | null;
};
export const useApiBasic = (url: string): ApiBasicState => {
  const state = useApi({
    method: 'GET',
    url,
  });
  return {
    ...state,
    url: state.config.url || url,
  };
};

export interface ApiConfig extends Omit<AxiosRequestConfig, 'signal'> {
  authenticate?: boolean;
  defer?: boolean;
};
type TDataBase = {};
export interface ApiState<TData extends TDataBase> {
  config: ApiConfig;
  loading: boolean;
  response: AxiosResponse | null;
  error: Error | null;
  reload: (data: TData) => void;
};
export const useApi = <TData extends TDataBase>(config: ApiState<TData>['config']) => {
  const [blockCall, setBlockCall] = useState(_.isNil(config.defer) ? false : config.defer);

  const [reloadIdx, setReloadIdx] = useState(0);
  const [reloadData, setReloadData] = useState<TData | null>(null);
  const reload = useCallback((data: TData) => {
    setBlockCall(false);
    setReloadIdx(v => v + 1);
    setReloadData(data);
  }, [setReloadIdx, setReloadData]);

  const [state, setState] = useState<ApiState<TData>>({
    config,
    reload,
    loading: false,
    response: null,
    error: null,
  });
  const token = useToken();

  const finalData = reloadData || config.data
    ? {
      ...config.data || {},
      ...reloadData || {},
    }
    : null;
  const finalConfig = {
    ...config,
    data: finalData,
  };

  useEffect(() => {
    if (finalConfig.authenticate && !token) return;
    if (blockCall) return;

    const abort = new AbortController();
    axios.request({
      ...finalConfig,
      signal: abort.signal,
      ...finalConfig.authenticate ? {
        headers: { Authorization: `Bearer ${token}` },
      } : {},
    })
      .then(response => setState(s => ({
        response,
        config: finalConfig,
        loading: false,
        error: null,
        reload: s.reload,
      })))
      .catch(error => setState(s => ({
        error,
        config: finalConfig,
        loading: false,
        response: null,
        reload: s.reload,
      })))
    setState(s => ({ ...s, loading: true }));
    return () => {
      abort.abort();
      setState(s => ({ ...s, loading: false }));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(finalConfig), token, reloadIdx, blockCall, setState]);
  return state;
};
