import _ from 'lodash';
import color from 'tinycolor2';

const baseColors = {
  bg: color('black'),
  text: color('white'),
  primary: color('#0fc'),
  secondary: color('#0cf'),
  muted: color('#011'),
  highlight: color('#f0c'),
  gray: color('#999999'),
  accent: color('#ffcd4f'),
};
const calendarColors = {
  todayHighlight: baseColors.primary,
  monthHeader: {
    bg: baseColors.primary,
    text: baseColors.bg,
    buttons: {
      text: baseColors.text,
      hoverText: baseColors.highlight,
    },
  },
  daysHeader: {
    bg: baseColors.primary,
    text: baseColors.bg,
  },
  superEvent: {
    bg: baseColors.accent,
    text: baseColors.bg,
  },
  holidayEvent: {
    bg: baseColors.secondary,
    text: baseColors.bg,
  },
  daysBg: baseColors.bg,
  dayBg: color('rgba(255, 255, 255, 0.1)'),
  eventTime: baseColors.secondary,
};
const spinnerColors = {
  border1: baseColors.primary,
  border2: baseColors.secondary,
  core: baseColors.muted,
  overlay: baseColors.bg.clone().setAlpha(0.5),
};

const theme = {
  colors: baseColors,
  calendar: {
    colors: calendarColors,
  },
  spinner: {
    colors: spinnerColors,
  },
};
export type Theme = typeof theme;
export default theme;
