import styled from 'styled-components';

import { Theme } from '../theme';
import InlineSpin, { InlineSpinProps } from './InlineSpin';

const Wrapper = styled.div`
  position: relative;
`;
const Overlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: ${props => (props.theme as Theme).spinner.colors.overlay.toRgbString()};
  display: flex;
  justify-content: center;
  align-items: center;
`;

export interface ContainerSpinProps extends InlineSpinProps {
  children: React.ReactNode;
  style?: React.CSSProperties;
};
const OverlaySpin: React.FC<ContainerSpinProps> = props => {
  return (
    <Wrapper style={ props.style }>
      { props.loading !== false && <Overlay><InlineSpin { ...props } /></Overlay> }
      { props.children }
    </Wrapper>
  );
};
export default OverlaySpin;
