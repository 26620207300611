import { useMemo } from 'react';
import styled from 'styled-components';

import { Theme } from '../theme';
import { CalendarMonthProps } from './CalendarMonth';

const CalendarContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
export const CalendarDayHeader = styled.div`
  font-weight: bold;
  background: ${props => (props.theme as Theme).calendar.colors.daysHeader.bg.toRgbString()};
  color: ${props => (props.theme as Theme).calendar.colors.daysHeader.text.toRgbString()};
`;
const CalendarDaySpacer = styled.div`
  margin-bottom: 20px;
`;

const noopRenderDayBody = () => {};

export interface CalendarListProps {
  style?: React.CSSProperties;
  date?: moment.Moment;
  defaultDate?: moment.Moment;
  days: moment.Moment[];
  onChange?: (date: moment.Moment) => void;
  renderDayBody?: CalendarMonthProps['renderDayBody'];
};
const CalendarList: React.FC<CalendarListProps> = props => {
  const { days, renderDayBody = noopRenderDayBody } = props;

  const sortedDays = useMemo(() => (
    days.sort((a, b) => a.valueOf() - b.valueOf())
  ), [days]);

  return (
    <CalendarContainer style={ props.style }>
      { sortedDays.map(date => (
        <>
          <CalendarDayHeader>{ date.format('dddd Do MMMM YYYY') }</CalendarDayHeader>
          { renderDayBody({ date }) }
          <CalendarDaySpacer />
        </>
      )) }
    </CalendarContainer>
  );
};
export default CalendarList;
