import { useAuth0 } from '@auth0/auth0-react';
import { useEffect } from 'react';

import OverlaySpin from '../components/OverlaySpin';

const LogoutPage = () => {
  const { logout } = useAuth0();
  useEffect(() => {
    logout();
  });
  return <OverlaySpin><div>Logging out...</div></OverlaySpin>;
}
export default LogoutPage;
