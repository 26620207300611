import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

import { usePatchScrapeTargets, useScrapeTargets } from '../api/scrapeTargets';
import { useTrigger } from '../api/trigger';
import OverlaySpin from '../components/OverlaySpin';
import VisibilityHandler from '../components/VisibilityHandler';

const Table = styled.table`
  & > tbody > tr > td:nth-child(1) {
    overflow: hidden;
    text-overflow: ellipsis;
    font-family: monospace;
    font-size: 0.7em;
  }
  & > tbody > tr > td:nth-child(2) {
    max-width: 400px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  & > tbody > tr > td:nth-child(3) {
    max-width: 400px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  & > tbody > tr > td:nth-child(6) {
    overflow: hidden;
    text-overflow: ellipsis;
    font-family: monospace;
    font-size: 0.7em;
  }
`;

interface RowProps {
  error: boolean;
}
const Row = styled.tr<RowProps>`
  background-color: ${props => props.error ? 'red' : 'none'};
`;

interface TriggerActionProps {
  id: string;
}
const TriggerAction: React.FC<TriggerActionProps> = props => {
  const { id } = props;
  const { reload } = useTrigger({
    defer: true,
  })
  const handleClick = useCallback(() => {
    reload({ ids: [id] });
  }, [reload, id]);
  return <button onClick={ handleClick }>TRIG</button>
};

interface GetEnabledButtonStateProps {
  enabled: boolean;
  loading: boolean;
};
const getEnabledButtonState = (props: GetEnabledButtonStateProps) => {
  const { enabled, loading } = props;
  const color = enabled ? 'red' : 'green';
  const state = {color, text: enabled ? '⏹️' : '▶️'};
  if (loading) return { ...state, text: `${state.text}⏳` };
  return state;
};
interface ToggleEnabledActionProps {
  id: string;
  enabled: boolean;
  patchItem: (props: PatchItemProps) => void;
};
const ToggleEnabledAction: React.FC<ToggleEnabledActionProps> = props => {
  const { id, enabled, patchItem } = props;
  const { loading, reload, response } = usePatchScrapeTargets({
    id,
    defer: true,
  });
  const respData = response?.data;
  useEffect(() => {
    if (respData) {
      console.log('patchItem', id, respData.enabled, respData);
      patchItem({ id, enabled: respData.enabled });
    }
  }, [patchItem, id, respData]);
  const handleClick = useCallback(() => {
    reload({ enabled: !enabled });
  }, [reload, enabled]);
  const { color, text } = getEnabledButtonState({ enabled, loading });
  return (
    <button
      onClick={ handleClick }
      disabled={ loading }
      style={{ backgroundColor: color }}
    >{ text }</button>
  );
};

interface PatchItemProps {
  id: string;
  enabled?: boolean;
};

const AuditScrapeTargetsPage: React.FC = () => {
  const api = useScrapeTargets();
  const { loading, error, items: rawItems, next } = api;

  const handleLoadMore = useCallback(() => {
    if (next) next();
  }, [next]);

  const [patches, setPatches] = useState<Record<string, PatchItemProps>>({});
  const patchItem = useCallback((props: PatchItemProps) => {
    setPatches(p => ({
      ...p,
      [props.id]: p[props.id] ? {...p[props.id], ...props}: props,
    }));
  }, [setPatches]);

  const items = useMemo(() => {
    if (!rawItems) return null;
    const items = rawItems.map(item => {
      const patch = patches[item.id];
      if (!patch) return item;
      return {...item, ...patch};
    });
    return items;
  }, [rawItems, patches]);
  console.log({ items, patches})


  return (
    <OverlaySpin loading={ loading }>
      { error && `Error: ${error}` }
      { items && <>
        <Table>
          <thead>
            <tr>
              <th>id</th>
              <th>comment</th>
              <th>url</th>
              <th>updateFreq</th>
              <th>until</th>
              <th>parentScrapeTargetId</th>
              <th>c.Failures</th>
              <th>ACTIONS</th>
            </tr>
          </thead>
          <tbody>
            { items.map(item => (
              <Row key={ item.id } error={ item.consecutiveFailures > 0 }>
                <td>{ item.id }</td>
                <td>{ item.comment }</td>
                <td>{ item.url }</td>
                <td>{ item.updateFreq }</td>
                <td>{ item.until?.toJSON() }</td>
                <td>{ item.parentScrapeTargetId }</td>
                <td>{ item.consecutiveFailures }</td>
                <td>
                  <ToggleEnabledAction id={ item.id } enabled={ item.enabled } patchItem={ patchItem } />
                  <TriggerAction id={ item.id } />
                </td>
              </Row>
            )) }
          </tbody>
        </Table>
        { next && <VisibilityHandler onVisible={ handleLoadMore }>Loading more...</VisibilityHandler> }
      </> }
    </OverlaySpin>
  )
};
export default AuditScrapeTargetsPage;
