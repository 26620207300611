import _ from 'lodash';
import type { Moment } from 'moment-timezone';
import moment from 'moment-timezone';
import { useMemo } from 'react';

import { BASE_API_URL } from '../config';
import { ApiBasicState, useApiBasic } from './util';

export interface Schedule {
  byDay?: string;
  byMonth?: number;
  byMonthDay?: number;
  byMonthWeek?: number;
  startTime?: string;
  endTime?: string;
};
export interface RawEventsListEvent {
  id: string;
  name: string;
  matchName: string;
  ticketUrl: string | null;
  description: string | null;
  startDate: string;
  endDate: string | null;
  doorTime: string | null;
  schedule: Schedule[] | null;
  locationName: string | null;
  locationAddress: string | null;
  locationLat: number | null;
  locationLng: number | null;
  organizerOrganizationId: string;
};
export interface EventsListEvent extends Omit<RawEventsListEvent, 'startDate' | 'endDate' | 'doorTime' | 'schedule'> {
  startDate: Moment;
  endDate: Moment | null;
  schedule: Schedule[] | null;
};

export interface EventsState extends ApiBasicState {
  events: null | EventsListEvent[];
};
export const useEvents = (): EventsState => {
  const state = useApiBasic(`${BASE_API_URL}/events`);
  const { response } = state;
  const events = useMemo<EventsState['events']>(() => {
    if (!response?.data) return null;
    return (response.data as RawEventsListEvent[]).map(raw => ({
      ...raw,
      startDate: moment(raw.startDate),
      endDate: _.isNil(raw.endDate) ? raw.endDate : moment(raw.endDate),
      schedule: _.isNil(raw.schedule) ? raw.schedule : raw.schedule as Schedule[],
    }));
  }, [response]);
  return {
    ...state,
    events,
  };
};

interface ExtraDetailFields {
  organizerName: string;
}
export interface RawEventDetailEvent extends RawEventsListEvent, ExtraDetailFields {
}
export interface EventDetailEvent extends EventsListEvent, ExtraDetailFields {
}
export interface EventState extends ApiBasicState {
  event: null | EventDetailEvent;
};
export const useEvent = (eventId: string): EventState => {
  const state = useApiBasic(`${BASE_API_URL}/events/${eventId}`);
  const { response } = state;
  const event = useMemo<EventState['event']>(() => {
    if (!response?.data) return null;
    const raw = response.data as RawEventDetailEvent;
    return {
      ...raw,
      startDate: moment(raw.startDate),
      endDate: _.isNil(raw.endDate) ? raw.endDate : moment(raw.endDate),
      schedule: _.isNil(raw.schedule) ? raw.schedule : raw.schedule as Schedule[],
    };
  }, [response]);
  return {
    ...state,
    event,
  };
};
