import { Auth0Provider } from '@auth0/auth0-react';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { createGlobalStyle, ThemeProvider } from 'styled-components';

import App from './App';
import { AUTH0_CLIENT_ID, AUTH0_DOMAIN } from './config';
import theme from './theme';

const GlobalStyle = createGlobalStyle`
  body {
    background: ${props => (props.theme as typeof theme).colors.bg.toRgbString()};
    color: ${props => (props.theme as typeof theme).colors.text.toRgbString()};
    font-family: "Avenir Next", system-ui, sans-serif;
  }
  a {
    color: ${props => (props.theme as typeof theme).colors.accent.toRgbString()};
    text-decoration: none;
  }
  a:hover {
    color: ${props => (props.theme as typeof theme).colors.highlight.toRgbString()};
  }
`;

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <ThemeProvider theme={ theme }>
      <GlobalStyle />
      <BrowserRouter>
        <Auth0Provider
          domain={ AUTH0_DOMAIN }
          clientId={ AUTH0_CLIENT_ID }
          redirectUri={ window.location.origin }
        >
          <App />
        </Auth0Provider>
      </BrowserRouter>
    </ThemeProvider>
  </React.StrictMode>
);
