import _ from 'lodash';
import type { Moment } from 'moment-timezone';
import moment from 'moment-timezone';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { BASE_API_URL } from '../config';
import { ApiState, useApi } from './util';

const SCRAPE_TARGET_API_URL = `${BASE_API_URL}/scrape-target`;

export interface RawScrapeTargetsListEvent {
  id: string;
  comment: string | null;
  url: string;
  updateFreq: string;
  until: string | null;
  parentScrapeTargetId: string | null;
  consecutiveFailures: number;
  enabled: boolean;
};
export interface ScrapeTargetsListEvent extends Omit<RawScrapeTargetsListEvent, 'until'> {
  until: Moment | null;
};

export interface ScrapeTargetsState extends ApiState<any> {
  items: null | ScrapeTargetsListEvent[];
  next: null | (() => void);
};
export const useScrapeTargets = (): ScrapeTargetsState => {
  const [nextToken, setNextToken] = useState<string | null>(null);
  const state = useApi({
    url: SCRAPE_TARGET_API_URL,
    params: nextToken ? { nextToken } : {},
    authenticate: true,
  });
  const { response } = state;

  const [items, setItems] = useState<ScrapeTargetsState['items']>(null);
  const responseItems = useMemo<ScrapeTargetsState['items']>(() => {
    if (!response?.data) return null;
    return (response.data.items as RawScrapeTargetsListEvent[]).map(raw => ({
      ...raw,
      until: raw.until ? moment(raw.until) : null,
    }));
  }, [response]);

  useEffect(() => {
    setItems(v => {
      if (!v || !responseItems) return responseItems;
      if (_.isEqual(v, responseItems)) return v;
      return [...v, ...responseItems];
    });
  }, [setItems, responseItems]);

  const respNextToken = response?.data?.nextToken;
  const next = useCallback(() => {
    if (!respNextToken) return;
    setNextToken(respNextToken);
  }, [respNextToken]);

  return {
    ...state,
    items,
    next: respNextToken ? next : null,
  };
};

export interface PatchScrapeTargetData extends Pick<ScrapeTargetsListEvent, 'enabled'> {
};

export interface PatchScrapeTargetProps {
  defer?: boolean;
  data?: PatchScrapeTargetData;
  id: string;
};
export interface PatchScrapeTargetState extends ApiState<PatchScrapeTargetData> {
};
export const usePatchScrapeTargets = (props: PatchScrapeTargetProps): PatchScrapeTargetState => {
  return useApi({
    ...props,
    url: `${SCRAPE_TARGET_API_URL}/${props.id}`,
    method: 'PATCH',
    authenticate: true,
  });
};
