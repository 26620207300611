import { BASE_API_URL } from '../config';
import { ApiState, useApi } from './util';

export interface Trigger {
  okay?: boolean;
  error?: string;
};
export interface TriggerData {
  ids: string[];
};

export interface TriggerProps {
  defer?: boolean;
  data?: TriggerData;
};
export interface TriggerState extends ApiState<TriggerData> {
};
export const useTrigger = (props: TriggerProps): TriggerState => {
  return useApi({
    ...props,
    url: `${BASE_API_URL}/trigger`,
    method: 'POST',
    authenticate: true,
  });
};
