import { useAuth0 } from '@auth0/auth0-react';
import { createContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import * as config from '../../config';
import { AUTH0_AUDIENCE } from '../../config';

import { Theme } from '../../theme';

export const TokenContext = createContext<string | null>(null);

const AppWrapperComp = styled.div`
`;
const AppWrapperHeader = styled.div`
  display: flex;
`;
const AppWrapperMenu = styled.div`
  flex-shrink: 1;
`;
const AppWrapperTitle = styled.h1`
  flex-grow: 1;
  color: ${props => (props.theme as Theme).colors.accent.toRgbString()};
`;
interface AppWrapperProps {
  children: React.ReactNode;
};
const AppWrapper: React.FC<AppWrapperProps> = props => {
  const nav = useNavigate();
  const handleAdminClick = () => {
    nav('/audit/scrape-targets');
  };
  const a0 = useAuth0();
  const { user, getAccessTokenSilently, getAccessTokenWithPopup } = a0;

  const [token, setToken] = useState<string | null>(null);

  console.log({
    a0, user, token,
  })

  useEffect(() => {
    if (user) {
      (async () => {
        for (const fn of [getAccessTokenSilently, getAccessTokenWithPopup]) {
          try {
            setToken(await fn({
              audience: AUTH0_AUDIENCE,
            }));
          } catch(err) {
            console.error(err);
          }
        }
      })();
    }
  }, [user, getAccessTokenSilently, getAccessTokenWithPopup]);

  useEffect(() => {
    console.log('Sending token to FUCKing Changes helper');
    window.postMessage({ action: 'setFuckingChangesToken', token }, '*');
  }, [token]);

  return <AppWrapperComp>
    <TokenContext.Provider value={ token }>
      <AppWrapperHeader>
        <AppWrapperTitle>FUCKing EVENTS</AppWrapperTitle>
        <AppWrapperMenu>
          { (user || token) && <button onClick={ handleAdminClick }>ADMIN</button> }
        </AppWrapperMenu>
      </AppWrapperHeader>
      { props.children }
    </TokenContext.Provider>
  </AppWrapperComp>
};
export default AppWrapper;
