import styled, { css } from 'styled-components';

// Generic support
const Icon = styled.svg`
  display: inline-block;
  width: 1em;
  height: 1em;
  stroke-width: 0;
  stroke: currentColor;
  fill: currentColor;
`;

const RotateLeftMixin = css`transform: rotate(270deg);`;
const RotateDownMixin = css`transform: rotate(180deg);`;
const RotateRightMixin = css`transform: rotate(90deg);`;

interface IconProps {
  className?: string;
};
const createUpIcon = (up: JSX.Element): React.FC<IconProps> => props => (
  <Icon viewBox="0 0 20 20" { ...props }>
    { up }
  </Icon>
);
const createLeftIcon = (up: React.FC<IconProps>) => styled(up)`${RotateLeftMixin}`;
const createDownIcon = (up: React.FC<IconProps>) => styled(up)`${RotateDownMixin}`;
const createRightIcon = (up: React.FC<IconProps>) => styled(up)`${RotateRightMixin}`;

// Icons
export const IconChevronUp = createUpIcon(
  <path d="M18.5 16c-0.082 0-0.165-0.020-0.242-0.063l-8.757-4.865-8.757 4.865c-0.241 0.134-0.546 0.047-0.68-0.194s-0.047-0.546 0.194-0.68l9-5c0.151-0.084 0.335-0.084 0.486 0l9 5c0.241 0.134 0.328 0.438 0.194 0.68-0.091 0.164-0.262 0.257-0.438 0.257z"></path>
);
export const IconChevronLeft = createLeftIcon(IconChevronUp);
export const IconChevronDown = createDownIcon(IconChevronUp);
export const IconChevronRight = createRightIcon(IconChevronUp);

export const IconChevronDoubleUp = createUpIcon(
  <>
    <path d="M18.5 16c-0.082 0-0.165-0.020-0.242-0.063l-8.757-4.865-8.757 4.865c-0.241 0.134-0.546 0.047-0.68-0.194s-0.047-0.546 0.194-0.68l9-5c0.151-0.084 0.335-0.084 0.486 0l9 5c0.241 0.134 0.328 0.438 0.194 0.68-0.091 0.164-0.262 0.257-0.438 0.257z"></path>
    <path d="M18.5 13c-0.082 0-0.165-0.020-0.242-0.063l-8.757-4.865-8.757 4.865c-0.241 0.134-0.546 0.047-0.68-0.194s-0.047-0.546 0.194-0.68l9-5c0.151-0.084 0.335-0.084 0.486 0l9 5c0.241 0.134 0.328 0.438 0.194 0.68-0.091 0.164-0.262 0.257-0.438 0.257z"></path>
  </>
);
export const IconChevronDoubleLeft = createLeftIcon(IconChevronDoubleUp);
export const IconChevronDoubleDown = createDownIcon(IconChevronDoubleUp);
export const IconChevronDoubleRight = createRightIcon(IconChevronDoubleUp);

export const IconCalendarInsertUp = createUpIcon(
  <>
    <path d="M18.5 2h-2.5v-0.5c0-0.276-0.224-0.5-0.5-0.5s-0.5 0.224-0.5 0.5v0.5h-3.5c-0.276 0-0.5 0.224-0.5 0.5s0.224 0.5 0.5 0.5h3.5v1.5c0 0.276 0.224 0.5 0.5 0.5s0.5-0.224 0.5-0.5v-1.5h2.5c0.276 0 0.5 0.224 0.5 0.5v2.5h-5.5c-0.276 0-0.5 0.224-0.5 0.5s0.224 0.5 0.5 0.5h5.5v10.5c0 0.276-0.224 0.5-0.5 0.5h-17c-0.276 0-0.5-0.224-0.5-0.5v-10.5h4.5c0.276 0 0.5-0.224 0.5-0.5s-0.224-0.5-0.5-0.5h-4.5v-2.5c0-0.276 0.224-0.5 0.5-0.5h2.5v1.5c0 0.276 0.224 0.5 0.5 0.5s0.5-0.224 0.5-0.5v-1.5h2.5c0.276 0 0.5-0.224 0.5-0.5s-0.224-0.5-0.5-0.5h-2.5v-0.5c0-0.276-0.224-0.5-0.5-0.5s-0.5 0.224-0.5 0.5v0.5h-2.5c-0.827 0-1.5 0.673-1.5 1.5v14c0 0.827 0.673 1.5 1.5 1.5h17c0.827 0 1.5-0.673 1.5-1.5v-14c0-0.827-0.673-1.5-1.5-1.5z"></path>
    <path d="M4.5 16h-1c-0.276 0-0.5-0.224-0.5-0.5s0.224-0.5 0.5-0.5h1c0.276 0 0.5 0.224 0.5 0.5s-0.224 0.5-0.5 0.5z"></path>
    <path d="M7.5 16h-1c-0.276 0-0.5-0.224-0.5-0.5s0.224-0.5 0.5-0.5h1c0.276 0 0.5 0.224 0.5 0.5s-0.224 0.5-0.5 0.5z"></path>
    <path d="M10.5 16h-1c-0.276 0-0.5-0.224-0.5-0.5s0.224-0.5 0.5-0.5h1c0.276 0 0.5 0.224 0.5 0.5s-0.224 0.5-0.5 0.5z"></path>
    <path d="M13.5 16h-1c-0.276 0-0.5-0.224-0.5-0.5s0.224-0.5 0.5-0.5h1c0.276 0 0.5 0.224 0.5 0.5s-0.224 0.5-0.5 0.5z"></path>
    <path d="M16.5 16h-1c-0.276 0-0.5-0.224-0.5-0.5s0.224-0.5 0.5-0.5h1c0.276 0 0.5 0.224 0.5 0.5s-0.224 0.5-0.5 0.5z"></path>
    <path d="M4.5 14h-1c-0.276 0-0.5-0.224-0.5-0.5s0.224-0.5 0.5-0.5h1c0.276 0 0.5 0.224 0.5 0.5s-0.224 0.5-0.5 0.5z"></path>
    <path d="M7.5 14h-1c-0.276 0-0.5-0.224-0.5-0.5s0.224-0.5 0.5-0.5h1c0.276 0 0.5 0.224 0.5 0.5s-0.224 0.5-0.5 0.5z"></path>
    <path d="M10.5 14h-1c-0.276 0-0.5-0.224-0.5-0.5s0.224-0.5 0.5-0.5h1c0.276 0 0.5 0.224 0.5 0.5s-0.224 0.5-0.5 0.5z"></path>
    <path d="M13.5 14h-1c-0.276 0-0.5-0.224-0.5-0.5s0.224-0.5 0.5-0.5h1c0.276 0 0.5 0.224 0.5 0.5s-0.224 0.5-0.5 0.5z"></path>
    <path d="M16.5 14h-1c-0.276 0-0.5-0.224-0.5-0.5s0.224-0.5 0.5-0.5h1c0.276 0 0.5 0.224 0.5 0.5s-0.224 0.5-0.5 0.5z"></path>
    <path d="M4.5 12h-1c-0.276 0-0.5-0.224-0.5-0.5s0.224-0.5 0.5-0.5h1c0.276 0 0.5 0.224 0.5 0.5s-0.224 0.5-0.5 0.5z"></path>
    <path d="M7.5 12h-1c-0.276 0-0.5-0.224-0.5-0.5s0.224-0.5 0.5-0.5h1c0.276 0 0.5 0.224 0.5 0.5s-0.224 0.5-0.5 0.5z"></path>
    <path d="M10.5 12h-1c-0.276 0-0.5-0.224-0.5-0.5s0.224-0.5 0.5-0.5h1c0.276 0 0.5 0.224 0.5 0.5s-0.224 0.5-0.5 0.5z"></path>
    <path d="M13.5 12h-1c-0.276 0-0.5-0.224-0.5-0.5s0.224-0.5 0.5-0.5h1c0.276 0 0.5 0.224 0.5 0.5s-0.224 0.5-0.5 0.5z"></path>
    <path d="M16.5 12h-1c-0.276 0-0.5-0.224-0.5-0.5s0.224-0.5 0.5-0.5h1c0.276 0 0.5 0.224 0.5 0.5s-0.224 0.5-0.5 0.5z"></path>
    <path d="M7.5 10h-1c-0.276 0-0.5-0.224-0.5-0.5s0.224-0.5 0.5-0.5h1c0.276 0 0.5 0.224 0.5 0.5s-0.224 0.5-0.5 0.5z"></path>
    <path d="M10.5 10h-1c-0.276 0-0.5-0.224-0.5-0.5s0.224-0.5 0.5-0.5h1c0.276 0 0.5 0.224 0.5 0.5s-0.224 0.5-0.5 0.5z"></path>
    <path d="M13.5 10h-1c-0.276 0-0.5-0.224-0.5-0.5s0.224-0.5 0.5-0.5h1c0.276 0 0.5 0.224 0.5 0.5s-0.224 0.5-0.5 0.5z"></path>
    <path d="M16.5 10h-1c-0.276 0-0.5-0.224-0.5-0.5s0.224-0.5 0.5-0.5h1c0.276 0 0.5 0.224 0.5 0.5s-0.224 0.5-0.5 0.5z"></path>
    <path d="M11.854 5.646c-0.195-0.195-0.512-0.195-0.707 0l-1.146 1.146v-4.293c0-0.276-0.224-0.5-0.5-0.5s-0.5 0.224-0.5 0.5v4.293l-1.146-1.146c-0.195-0.195-0.512-0.195-0.707 0s-0.195 0.512 0 0.707l2 2c0.098 0.098 0.226 0.146 0.354 0.146s0.256-0.049 0.354-0.146l2-2c0.195-0.195 0.195-0.512-0-0.707z"></path>
  </>
);
